//
//  Variables
//  _____________________________________________

:root {
    --active-nav-indent: 108px;

    @include max-screen($screen__xs - 1) {
        --active-nav-indent: 55px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {

    .header.content {
        & > .action.nav-toggle {
            margin-bottom: 10px;
        }
    }
    .nav-sections {
        transition: left .3s;
        height: 100%;
        left: -100%;
        overflow: auto;
        position: fixed;
        top: 0;
        width: 100%;
        background-color: $sg-color-primary-01;

        &-item-title {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0px 15px;

            //close icon
            .material-icons {
                display: block;
                font-size: 22px;
                opacity: .7;
            }
        }

        &-item-switch {
            display: none;
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left .3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: calc(100% - #{var(--active-nav-indent)});
        }

        .nav-sections {
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            //This is the overlay
            &:after {
                background: $sg-color-overlay;
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    @include lib-main-navigation(
        $_nav__indent-side: 0,
        $_nav-background-color: transparent,
        $_nav-level0-item-color: $color__black,
        $_nav-border: none,
        $_nav-level0-font-size: 20px,
        $_nav-level0-font-weight: 400,
        $_nav-level0-item-line-height: 1,
        $_nav-level0-item-padding: 0 24px,
        $_nav-level0-text-transform: none,
        $_nav-level0-item-border: none,
        $_nav-level0-item__active__border-width: 0,
        $_submenu-font-size: 14px,
        $_submenu-line-height: 1,
        $_submenu-item__padding-top: 0,
        $_submenu-item__padding-right: 0,
        $_submenu-item__padding-bottom: 0,
        $_submenu-item__padding-left: 0
    );

    .navigation {
        padding: 0;
        position: relative;

        a {
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
        }

        .level0 {
            > .level-top {

            }

            & > .level1 {
                font-weight: 400;
            }
        }

        .submenu {
            &:not(:first-child) {
                & > li {
                    & > a {
                        padding-left: 24px;
                    }
                }

                ul {
                    & > li {
                        a {
                            display: flex;
                        }
                    }
                }

                &.expanded {
                    padding-left: 24px;
                }
            }
        }

        .parent {
            .ui-menu-icon {
                display: none;
            }

            .level-top {
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 24px,
                    $_icon-font-position: after,
                    $_icon-font-display: flex,
                    $_icon-font-color: $primary__color
                );

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content : $icon-up,
                        $_icon-font-position: after
                    );

                    &:after {
                        color: $color__black;
                        opacity: .5;
                    }

                    font-weight: 700;
                }
            }
        }
    }

    //for mega-menu mobile view

    .sections.nav-sections {

        .section-item-title {
            & > span {
                padding: 10px 0;
            }
        }

        .section-item-content {
            .navigation {
                nav.navigation {
                    & > ul.ui-menu.ui-widget {
                        & > li.subhover {
                            background-color: #FFF8F5;
    
                            & > a.nav-anchor {
                                background-color: transparent !important;
                                color: #575757;
                                height: auto;

                                .opener {
                                    width: 100%;
                                    display: flex;
                                    justify-content: right;
                                    
                                    &::before {
                                       margin-right: 15px;     
                                    }
                                }
                            }
    
                            & > div.submenu.dropdown-menu {
                                div.subgroup {
                                    & > a.nav-anchor {
                                        color: #575757;
                                        background-color: transparent !important;
    
                                        img {
                                            display: none;
                                        }

                                        .opener {
                                            width: 100%;
                                            display: flex;
                                            justify-content: right;
                                            
                                            &::before {
                                               margin-right: 15px;     
                                            }
                                        }
                                    }

                                    .submenu.dropdown-mega {

                                        div.item-content2 {
                                            .nav-item.level2 {
                                                &.active > a {
                                                    background-color: transparent !important;
                                                    color: black;
                                                }
                                            }
                                        }
                                        
                                        div.see-all-btn > a {
                                            background-color: transparent !important;
                                            color: #575757;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .store-selector__menu {
            &::before {
                display: none;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .header-nav {
        &-wrapper {
            background-color: $navigation__background;
        }

        &-content {
            width: 100%;
            max-width: $layout__max-width;
            margin: 0 auto;
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }
    }

    .nav-sections {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-item-title {
            display: none;
        }

        .section-items {
            width: 100%;
        }
    }

    @include lib-main-navigation-desktop(
        $_nav-background-color: $navigation__background,
        $_nav-font-size: 14px,
        $_nav-font-weight: 700,
        $_nav-level0-item-margin: 0,

        $_nav-level0-item-color: #fff,
        $_nav-level0-item-color-hover: $primary__color,
        $_nav-level0-item-color-active: $primary__color,

        $_nav-level0-item-background-color: transparent,
        $_nav-level0-item-background-color-active: $sg-color-primary-01,
        $_nav-level0-item-background-color-hover: $sg-color-primary-01,

        $_nav-level0-item__active__border-width: 0,

        $_submenu-arrow: false,
        $_submenu-font-weight: 400,
        // $_submenu-border-color: $primary__color,

        $_submenu-item-color: $color__black,
        $_submenu-item-color-hover: $sg-color-primary-08,
        $_submenu-item-padding: 0 16px,
        $_submenu-item__hover__background-color: $sg-color-primary-02,

        $_submenu-box-shadow: none,

        $_submenu-parent-icon: '\e5cc',
        $_submenu-parent-icon-position: right,
        $_submenu-parent-icon-color: $primary__color,
        $_submenu-parent-icon-size: 16px
    );

    .navigation {
        z-index: 9;

        ul {
            display: flex;
            border-top: 1px solid $navigation__background;
            border-bottom: 1px solid $navigation__background;

            a {
                transition: $transition-base;
            }
        }

        .level0 {
            & > .level-top {
                &.ui-state-active {
                    background: $sg-color-primary-01;
                    color: $primary__color;
                }
            }

            .submenu {
                .category-item.parent {
                    margin: 0;

                    a {
                        display: flex;
                        align-items: center;
                        height: 47px;
                    }
                }
            }
        }
    }

    .custom-menu-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: space-evenly;
        display: none;

        &:before {
            content: ' ';
            width: 1px;
            border-left: 1px solid #fff;
        }

        &:after {
            content: ' ';
            width: 1px;
            border-right: 1px solid #fff;
        }

        .navigation {
            width: auto;
            margin: 0;
        }
    }

    //for mega-menu desktop view
    .submenu-inner.before-ves-submenu-inner {
        background-color: #fff8f2;
    }

    nav.navigation {

        & > ul {
            display: flex;
            justify-content: center;

            & > li.nav-item.level0 {

                & > a {
                    color: white;
                    font-family: "Open Sans", sans-serif;
                }

                &:hover > a {
                    color: #ff6900 !important;
                    background-color: white !important;
                }

                &.active {
                    & > a {
                        background-color: transparent !important;
                        color: black;
                    }

                    &:hover > a {
                        background-color: white !important;
                    }
                }
                

                &.current > a {
                    background-color: transparent;
                }

                &:last-child {
                    display: none;
                }
            }
        }
    }

    .item-content {
        .mega-col-level-1>.subgroup {
            background-color: transparent;
            min-height: 300px;

            & > a.nav-anchor.subitems-group {
                border: none !important;
                font-family: Open Sans, sans-serif;
                font-weight: 800;
                text-transform: uppercase;
                padding-top: 30px !important; 
                padding-bottom: 20px !important;
                background-color: transparent !important;
                color: #3b3b3b;
                height: auto;

                span {
                    width: 100%;
                    padding-left: 10px;
                    font-size: large;
                }

                img.item-icon {
                    width: 36px;
                    height: 36px;
                }

                &::after {
                    display: none !important;
                }
            }

            &.active > a.nav-anchor.subitems-group {
                background-color: transparent !important;

                span {
                    color: black;
                }
            }

            .submenu.dropdown-mega {
                .mega-col.mega-col-level-2 {
                    div.nav-item {
                        a.nav-anchor {
                            padding: 6px 20px;
                            font-family: "Open Sans", sans-serif;
                            font-size: 1.1em;
                        }

                        &.see-all-btn {
                            text-decoration: underline;
                            font-weight: bold;

                            &.active > a {
                                background-color: transparent !important;
                                color: black;
                            }
                        }

                        &:hover {
                            & > a.nav-anchor {
                                color: #ff6900;
                                text-decoration: none;
                                background-color: transparent !important;
                                border-left: 3px solid #ff6900;
                            }
                        }

                        &.active {
                            & > a.nav-anchor {
                                background-color: transparent !important;
                                color: #000000;
                                font-weight: 600;
                            }
                        }
                    } 
                }
            }

            .submenu.dropdown-mega {
                .mega-col-level-2 {
                    & > div:first-child {
                        & a > span {
                            border-top: 1px solid black;
                            width: 100%;
                            display: inline-block;
                        }

                    }
                }
            }

            &:hover > a.nav-anchor.subitems-group {
                color: #ff6900;
                background-color: transparent;
            }
        }
    }
}

/* Shitty exception for tablet that won't work when number of menu items will change */
@include screen($screen__m, $screen__l) {
    .navigation {
        font-size: 12px;
    }
}
