//
//  Common
//  _____________________________________________

body {
    background-color: $page__background-color;
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $header__background-color;
}

.page-main {
    margin-bottom: 3rem;
    padding: 0;
    position: relative;

    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

@include max-screen($screen__m) {
    body:not(.cms-index-index) .page-main {
        padding: 1rem;
        // Space for block-collapsible
    }

}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        background-color: $color__lightgrey2;
        color: $color__darkergrey;
        font-size: $font-size__base;
        display: flex;
        justify-content: center;
        align-items: center;

        @include max-screen($screen__m) {
            padding-bottom: 55px;
            padding-top: 20px;
        }

        .actions {
            margin-top: $indent__s;
            display: flex;
            justify-content: center;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        margin-bottom: $indent__xl;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-widget-title;
    }
}

.page-header {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker td {
    padding: 0;
}

.ui-tooltip {
    background: $tooltip__background;
    border: $tooltip__border-width solid $tooltip__border-color;
    padding: 10px;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0;
        min-height: 100%; // Stretch content area for sticky footer
        position: relative;
        transition: margin 0.3s ease-out 0s;

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }
    }

    .navigation.show-on-mobile {
        display: none;
    }
}
