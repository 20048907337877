input[type="radio"]:checked {
    background: #2196f3;
}
input[type="radio"] {
    background: #ffffff;
    border: 2px solid #3b3f49;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

/*
 see Snowdog/blank/styles/mixins/_media-queries.scss
 screen range for tablets/halfscreen
*/
@include screen($screen__m, ($layout__max-width)) {

    .page-wrapper .breadcrumbs {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .page-main {
        padding: 0 1rem !important;
    }

}
