#html-body {
    .kurs-row-group {
        .kurs-page-title {
            margin: 0 0 2rem 0;
        }
        .divider {
            margin-bottom: 1rem;
            border-style: none;
        }
        .pagebuilder-column-group {
            display: grid !important;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 1rem auto;
            grid-gap: 1rem;
            .pagebuilder-column {
                border-color: #ff5e03;
                height: 535px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 0 auto;
                figure {
                    height: 142px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .kurs-column-title {
                    margin: 1rem;
                    font-weight: 700;
                }
                .kurs-column-text {
                    margin: 0 1rem 1rem 1rem;
                }
                .kurs-btn-container {
                    margin: 0 0 1rem 1rem;
                    .kurs-btn {
                        .pagebuilder-button-primary {
                          background-color: #fff;
                          font-size: 24px;
                          color: black;
                          font-weight: 700;
                          border-radius: 2px;
                          border: 1px solid #ff5e03;
                          width: fit-content;
                        }
                    }
                }
            }
        }
    }

}

//
//  Tablet
//  _____________________________________________
@include screen($screen__m, $screen__l){ 
    #html-body {
        .kurs-row-group {
            .divider {
                margin-bottom: 0;
            }
            .pagebuilder-column-group {
                grid-template-columns: 1fr 1fr;
                .pagebuilder-column {
                    .kurs-column-title {
                        text-align: center;
                    }
                    .kurs-column-text {
                        font-size: 22px;
                        text-align: center;
                    }
                    .kurs-btn-container {
                        margin: 1rem auto;
                    }
                }
            }
        }
    }
}
//
//  mobile
//  _____________________________________________
@include max-screen($screen__m){ 
    #html-body {
        .kurs-row-group {
            .kurs-page-title{
                margin-bottom: 1rem;
            }
            .pagebuilder-column-group {
                grid-template-columns: 1fr;
                margin-bottom: 1rem;
                .pagebuilder-column {
                    height: fit-content;
                    .kurs-column-title {
                        text-align: center;
                        font-size: 24px;
                    }
                    .kurs-column-text {
                        font-size: 18px;
                        text-align: center;
                    }
                    .kurs-btn-container {
                        margin: 1rem auto;
                        .kurs-btn {
                            .pagebuilder-button-primary {
                                padding: 0.3rem 0.5rem;
                                span {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}