$weirdblue: rgba(73, 166, 206, 0.2);
$weirdgrey: #303031;

.store-selector {
    &__modal {
        .modal-header {
            display: none;
        }

        .modal-content {
            padding: 20px;
        }

        .modal-inner-wrap {
            width: 100%;
            max-width: 450px;
        }
    }

    &__logo {
        width: 120px;
        margin: 0 auto 40px;

        .action.nav-toggle {
            display: none;
        }
    }

    &__text {
        max-width: 385px;
        margin: 0 auto 30px;
        text-align: center;
    }

    &__container {
        display: flex;
        justify-content: center;
        max-width: 406px;
        margin: auto;
    }

    &__link {
        background: $weirdblue;
        color: $weirdgrey;
        width: 50%;
        height: 57px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-left: 30px;
            line-height: 1;

            &:before {
                position: absolute;
                width: 25px;
                height: 24px;
                left: 0;
                top: 2px;
            }
        }

        &--company > span:before {
            content: url("data:image/svg+xml,<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M20 7.31818H4C2.89543 7.31818 2 8.21361 2 9.31818V19.3182C2 20.4227 2.89543 21.3182 4 21.3182H20C21.1046 21.3182 22 20.4227 22 19.3182V9.31818C22 8.21361 21.1046 7.31818 20 7.31818Z' stroke='%23303031' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M16 21.3182V5.31818C16 4.78774 15.7893 4.27904 15.4142 3.90396C15.0391 3.52889 14.5304 3.31818 14 3.31818H10C9.46957 3.31818 8.96086 3.52889 8.58579 3.90396C8.21071 4.27904 8 4.78774 8 5.31818V21.3182' stroke='%23303031' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
        }

        &--private > span:before {
            content: url("data:image/svg+xml,<svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.5 9.31818L12.5 2.31818L21.5 9.31818V20.3182C21.5 20.8486 21.2893 21.3573 20.9142 21.7324C20.5391 22.1075 20.0304 22.3182 19.5 22.3182H5.5C4.96957 22.3182 4.46086 22.1075 4.08579 21.7324C3.71071 21.3573 3.5 20.8486 3.5 20.3182V9.31818Z' stroke='%23303031' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M9.5 22.3182V12.3182H15.5V22.3182' stroke='%23303031' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
        }

        &:hover,
        &:active {
            color: $weirdgrey;
            text-decoration: none;
        }
    }

    &__vat {
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
    }

    &__menu {
        display: flex;
        justify-content: center;

        a.store-selector__menu-link {
            color: #fff;

            &.active {
                font-weight: 700;
                text-decoration: underline;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &-link {
            display: flex;
            align-items: center;
            padding: 0 8px;
            height: 46px;

            @include screen($screen__m, $screen__l) {
                font-size: 12px;
            }
        }
    }
}

// Mobile
@include max-screen($screen__m - 1) {
    .store-selector {
        &__menu {
            padding: 0 24px;
            margin: 25px 0;
            position: relative;

            &:before {
                content: ' ';
                position: absolute;
                height: 1px;
                border-top: 1px solid $primary__color;
                left: 24px;
                right: 24px;
                top: -12px;
            }

            a.store-selector__menu-link {
                color: $primary__color;
            }

            &-link {
                height: 54px;
            }
        }
    }
}
