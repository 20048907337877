// custom ajaxcart design
#ajaxcart {
    .inner {
        .button-close-top {
            color: #fff;
        }
        .success {
            background-color: $primary__color;
            .icon {
                display: none;
            }
            .message {
                color: #fff;
                display: flex;
                align-items: center;
                &:before {
                    content: "\E5CA";
                    font-family: 'Material icons';
                    font-size: 24px;
                    font-size: 24px;
                    display: flex;
                    margin-right: 5px;
                }
            }
        }
        .product {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            img {
                float: left;
                margin-right: 1rem;
                margin-bottom: 1rem;
                width: 78px;
            }
            .product-name {
                font-size: 1.5rem;
                width: 100%;
            }
            .price-box {
                .price-including-tax {
                    .minicart-price {
                        .price {
                            font-size: $font-size__base;
                        }
                    }
                }
            }
        }
        .actions {
            clear: both;
            border-top: 1px solid #c8c8c8;
            #button-checkout {
                border-color: $color__darkblue;
                background-color: $primary__blue;
                border-bottom: 2px solid $color__darkblue;
            }
        }
    }
}
// mobile styling
@include max-screen($screen__s){
    #ajaxcart {
        .inner {
            .success {
                .icon {
                }
                .message {
                }
            }
            .product {
                width: 100%;
            }
            .actions {
                #button-close {
                    order: 30;
                    border: 0;
                    background-color: transparent;
                    text-decoration: underline;
                }
            }
        }
    }
}
