//
//  Common
//  _____________________________________________

.block-search {
    position: relative;
    max-width: 398px;
    width: 100%;

    .block-title {
        display: none;
    }

    .block-content {
        margin-bottom: 0;
    }

    .field.search {
        position: relative;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .action.search {
        display: block;
        margin: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background: transparent;

        &:before {
            display: block;
            background-position: center;
            width: 20px;
            height: 20px;
            content: '';
            background-image: svg-uri("<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='#{$sg-color-gray-09}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/><path d='M20.9999 21L16.6499 16.65' stroke='#{$sg-color-gray-09}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
        }

        > span {
            @extend .abs-visually-hidden;
        }
    }

    .control {
        display: flex;
        border: 1px solid $color__grey9;
        border-radius: 2px;
        margin: 0;
        padding: 0;
    }

    input {
        background-color: transparent;
        height: 40px;
        font-size: 12px;
        padding-right: 35px;

        @include lib-input-placeholder() {
            color: $color__black;
            opacity: 1;
        }
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 45px;
    left: -90px;
    width: 800px !important; //overrides inline style
    box-shadow: 0 2px 4px #888;
    background-color: #fff;
    z-index: 30;

    @include max-screen($screen__l) {
        width: 620px !important; //overrides inline style
    }

    @include max-screen($screen__m) {
        left: -82px;
        right: 0;
        width: auto !important; //overrides inline style
    }

    .smile-elasticsuite-autocomplete-result {
        display: flex;
        flex-wrap: wrap;

        .autocomplete-list {
            @include max-screen($screen__m) {
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                border-top: 1px solid $sg-color-gray-09;
            }

            dt, dd {
                margin: 0;
            }

            &:nth-child(1) {
                display: flex;
                flex: 0 0 100%;
                order: 30;
                padding: 2%;
                margin: 0;
                justify-content: center;
                background-color: $tertiary__color;

                dt {
                    margin-right: 10px;
                }
            }

            &:nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                flex: 0 0 70%;
                order: 10;
                border-right: 1px solid $tertiary__color;

                & > * {
                    width: 48%;
                    margin-right: 2%;
                    padding: 2%;

                    .product-image-box {
                        float: left;
                    }

                    .product-shop.product-item {
                        margin-left: 60px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        border: 0;
                        padding: 0;
                    }

                    .product-secondary {
                        font-size: 0.8em;
                        color: $color__text;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 100%;
                        text-overflow: ellipsis;
                    }
                }
            }

            &:nth-child(3) {
                flex: 0 0 30%;
                padding: 2%;
                order: 20;
            }
        }

        .autocomplete-list-title.title-product {
            display: none;
        }

        .autocomplete-list-title.title-category {
            border-bottom: 1px solid $primary__color;
            color: $color__black;
            font-size: 16px;
            padding: 0 0 0.5rem;
            margin: 0 0 0.5rem;
            text-transform: uppercase;
            font-weight: 300;
        }
    }
}
