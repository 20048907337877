ul[data-content-type="accordion"] {
    list-style: none;
    margin: 0;
    padding: 0;

    .js-accordion-open {
        span[data-element="headline"] {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            &:after {
                transform: rotate(180deg);
            }
        }

        [data-element="content"] {
            border-color: $color_alto;
        }
    }

    [data-element="content"] {
        height: 0;
        transition: 0.4s;
        overflow: hidden;
        border: 1px solid transparent;
        border-top: none;

        p {
            padding: 0;
            margin: 0;
        }

        > div {
            height: unset !important;
            padding: 15px;
        }
    }

    span[data-element="headline"] {
        display: flex;
        align-items: center;
        border: 1px solid $color_alto;
        color: $color_mine_shaft;
        background-color: $color_wild_sand;
        padding: 10px 15px;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
        cursor: pointer;

        &:after {
            transition: all .3s linear;
            flex-shrink: 0;
            width: 1.75rem;
            height: 1.75rem;
            margin-left: auto;
            content: "";
            background-repeat: no-repeat;
            background-size: 1.75rem;
            background-image: svg-uri(
                    '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="#FF6900" class="w-6 h-6"> <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>'
            );
        }
    }
}


