.card-services {
	box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 
				0 1px 10px 0 rgba(0,0,0,.12), 
				0 2px 4px -1px rgba(0,0,0,.2);
	border-radius: 11px;
	position: relative;
	overflow: hidden;
}

.card-services .wpcloud-image2__picture {
	display: flex;
}

.card-services .wpcloud-headline {
	position: absolute;
	top: 3rem;
	left: 45%;
}

.card-services figure.wpcloud-image2 {
	margin: 0;
	width: 100%;
	padding: 2rem;
	background: #c5c5c5;
}

.card-services figure.wpcloud-image2 img {
	width: 110px;
	border-radius: 50%;
	padding: 0.5rem;
	filter: invert(100%);
	margin-left: 12%;
}

.card-services .wpcloud-headline .wpc-headline {
	padding: 1rem;
	margin: 0;
	color: #ffffff;
	font-size: 36px;
}
.card-services .wpcloud-post-list .mdl-list {
	padding: 0;
	margin: 0.5rem 1rem;
}

.card-services .wpcloud-post-list .mdl-list .mdl-list__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;
	padding: 0.5rem 0;
	border-bottom: 1px solid #dbdbdb;

	a {
		color: $text__color;
	}

	&:last-child {
		border-bottom: none;
	}

	&::after {
		display: flex;
		content: '';
		width: 1rem;
		height: 1rem;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj4KICA8cGF0aCBmaWxsPSIjMjIyIiBkPSJNMTkxIDY3bDIyLTIyYzktMTAgMjQtMTAgMzQgMGwxOTQgMTk0YzkgOSA5IDI1IDAgMzRMMjQ3IDQ2N2MtMTAgMTAtMjUgMTAtMzQgMGwtMjMtMjJjLTktOS05LTI1IDEtMzRsMTIwLTExNUgyNGMtMTMgMC0yNC0xMS0yNC0yNHYtMzJjMC0xMyAxMS0yNCAyNC0yNGgyODdMMTkxIDEwMWMtMTAtOS0xMC0yNSAwLTM0eiIvPgo8L3N2Zz4=");
		background-repeat: no-repeat;
		background-position: center center;
	}
}
