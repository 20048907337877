// custom category and sidebar design

// custom category body styling 
.page-products {
    background-color: #fff;
    .breadcrumbs {
        background-color: #f5f5f5;
        max-width: 100%;
        .items {
            max-width: 1400px;
            margin: 0 auto;
        }
    }
}
// category head
.p2-category-top {
    margin-top: 2rem;
    padding-left: 1rem;
    .page-title-wrapper h1 {
        span {
            color: $primary__color;
            font-size: 32px;
            font-weight: 700;
        }
    }
    .category-view {
        .category-description {
            border-bottom: 0;
        }
    }
}
// mobile styling 
@include max-screen($screen__m){ 
    .p2-category-top {
        margin-top: 1rem;
        .page-title-wrapper h1 {
            margin-top: 0;
        }
        .category-view .category-description {
            margin-bottom: 0;
        }
    }
}    
// tablet styling 
@include screen($screen__s, $screen__l){ 
    .p2-category-top {
        margin-top: 0.5rem;
        padding-left: 0;
    }
}    

// sidebar 
.sidebar-additional {
    display: none;
}
.sidebar.sidebar-main {
    margin-top: 2rem;
    #layered-filter-block {
        .block-content.filter-content {
            .filter-current {
                .filter-current-subtitle {
                    font-size: 16px;
                    color: $text__color;
                    font-weight: 300;
                    @include max-screen($screen__m) {
                        font-size: $font-size__s;
                        text-transform: initial;
                    }
                }
                .items .item {
                    display: inline-block;
                    padding: 3px 2.5rem 3px 0.4rem;
                    border: 1px solid #5e5e5e;
                    border-radius: 15px;
                    .filter-value {
                        color: #474747;
                        font-size: $font-size__base;
                        line-height: 1;
                    }
                    .filter-label {
                        display: none;
                    }
                    .action.remove {
                        left: auto;
                        top: 2px;
                        right: 4px;
                        &:before {
                            content:"\E5C9";
                            font-size: 22px;
                            color: #474747;
                            text-indent: inherit;
                            line-height: 22px;
                        }
                    }
                }
            }
            .block-actions.filter-actions {
                margin: 0;
                .action.clear.filter-clear {
                    display: none;
                }
            }
            #narrow-by-list {
                .filter-options-item {
                    border: 1px solid #d3d3d3;
                    padding: unset;
                    margin: unset;
                    .filter-options-title {
                        color: #5e5e5e;
                        font-size: 13px;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 1.2rem 0.75rem;
                        font-family: "Open Sans";
                        &:after {
                            content: '+';
                            display: block;
                            color: #ff6900;
                            position: absolute;
                            right: 8px;
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            text-align: center;
                            top: 10px;
                            font-size: 30px;
                            font-weight: 500;
                            overflow: visible;
                        }
                    }
                    .filter-options-content {
                        display: none;
                        .items > .item:hover > a {
                            text-decoration: underline;
                            cursor: pointer;
                            & > label {
                                cursor: pointer;
                            }
                        }
                    }

                    &.active {
                        .filter-options-title {
                            border-bottom: 1px solid #d3d3d3;
                            &:after {
                                content: '\2212';
                            }
                        }
                    }

                }
            }
        }
    }
    [type="radio"] {
        display: none;
        & + span {
            display: inline;
            &:before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                line-height: 1;
                text-align: center;
                border-radius: 2px;
                border: 1px solid #666666;
                margin-right: 0.75em;
                position: relative;
                top: 2px;
            }
        }
        &:checked + span:before {
            content: '\2713';
            top: 0;
        }
    }
}
// toolbar settings
.toolbar.toolbar-products {
    margin-bottom: 1rem;
    justify-content: space-between;
    padding-left: 1rem;
    align-items: flex-end;
    &:before {
        display: none;
    }
    &:after{
        display: none;
    }
    .modes {
        display: none;
    }
    .toolbar-amount {
       margin: 0; 
       padding-bottom: 0;
    }
    .field.limiter {
        display: none;
    }
}
// Tablet styling 
@include screen($screen__s, $screen__l){ 
    .toolbar.toolbar-products {
        padding-left: 0;
    }
}    

.toolbar-sorter.sorter {
    border: 1px solid #757575;
    border-radius: 3px;
    background-color: #fff;
    #sorter {
        background-color: #fff;
        border: none;
        .sorter-label {
            text-align: left;
        }
    }
}
// Mobile filtering styling
@include max-screen($screen__m){ 
    .toolbar-sorter.sorter {
        height: 50px;
        width: calc(50% - 0.5rem);
        #sorter {
            height: 46px;
            text-align: left;
            text-align-last: left;
            padding-left: 3px;
            .sorter-label {
            }
        }
    }
    #amasty-shopby-product-list .catalog-topnav {
        display: none;
    } 
    .sidebar.sidebar-main {
        margin-top: 0;
        #layered-filter-block {
            .block-title.filter-title {
                strong {
                    background-color: #fff;
                    border: 1px solid #757575;
                    border-radius: 3px;
                    justify-content: start;
                    width: calc(50% - 0.5rem);
                    height: 50px;
                    &:after {
                        content: "\E313";
                        font-family: "Material icons";
                        font-size: 24px;
                        position: absolute;
                        display: inline-block;
                        right: 0;
                        top: 50%;
                        transform: translateY(-46%);
                    }
                    &:before {
                        display: none;
                    }
                }
            }
            &.active {
                .block-title.filter-title strong {
                    border: none;
                    border-radius: 0;
                    &:after {
                        content: "\E14C";
                        top: 1rem;
                        right: 1rem;
                    }
                }
                .block-content.filter-content {
                    .block-subtitle.filter-subtitle {
                        border-bottom: 0;
                    }
                }
            }
            .filter-current {
                .items {
                    display: block;
                }
            }
        }
    }
}
body .filter-options .am-labels-folding .item a.amshopby-filter-parent, .filter-options .am-category-view .item a.amshopby-filter-parent {
    padding-left: 0;
}