a.action.primary,
button {
    border-radius: 0px;
}

.action,
button {
    &:active {
        box-shadow: $button__shadow;
    }
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary(
            $_button-border: $polarcore-primary-button__border,
            $_button-border-hover: $polarcore-primary-button__border,
            $_button-border-active: $polarcore-primary-button__border,
            $_button-color: $polarcore-primary-button__color,
            $_button-background: $polarcore-primary-button__background,
            $_button-background-hover: darken($polarcore-primary-button__background, 8%),
            $_button-background-active: darken($polarcore-primary-button__background, 8%)
    );
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 0;
    box-shadow: 0 1px 0 0 darken($polarcore-primary-button__background, 25%);
}

.action.primary.cms {
    @include lib-button-primary(
        $_button-border: 0,
        $_button-border-hover: 0,
        $_button-border-active: 0,
        $_button-color: $polarcore-primary-button__color,
        $_button-background: $polarcore-primary-button__background,
        $_button-background-hover: $polarcore-primary-button__background,
        $_button-background-active: $polarcore-primary-button__background
    );
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 5px;
    box-shadow: 0 1px 8px rgba(0,0,0,.15);
}
