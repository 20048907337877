// custom styling for popup ask for price
.catalog-product-view .modal-popup .modal-inner-wrap {
    .modal-header {
        padding: 0.5rem 0 1rem 0;
    }
    .modal-content {
        #te-pricing-modal {
            .te-pricing__actions {
                text-align: right;
                #te-pricing-submit {
                    background-color: $primary__blue;
                    color: #fff;
                    font-size: $font-size__base;
                    padding: 1rem 2rem;
                    &:hover {
                        background-color: #0087a9;
                    }
                }
            }
            .te-pricing__fieldset .te-pricing__field #te-pricing-input-newsletter {
                margin-top: 10px;
            }
        }
    }
}
// mobile styling ¨
@include max-screen($screen__m){
    .catalog-product-view .modal-popup {
        max-width: 300px;
        left: calc(50% - 150px);
        top: 2vh;
        max-height: 95vh;
        overflow: auto;
        .modal-inner-wrap {
            .modal-header {
                padding: 0;
                .action-close {
                    padding: 9px;
                }
            }
            .modal-content {
                padding: 0 1rem;
                #te-pricing-modal {
                    #te-pricing-form { 
                        .te-pricing__fieldset {
                            margin-bottom: 1.5rem;
                        }    
                    }
                }
            }
        }    
    }
}
