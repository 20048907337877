//
//  Product Lists
//  _____________________________________________

.product-items {
    list-style: none;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 16px 8px;
    margin: 0;
    padding: 0;

    @include min-screen($screen__xs) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include min-screen($screen__l) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include min-screen($screen__xl) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.product-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin: 0;
    background-color: $color_white;
    border: 0.5px solid $sg-color-primary-03;
    border-radius: 2px;
    transition: $transition-base;

    a {
        text-decoration: none;
        color: $color__black;
    }

    &:hover {
        background: $sg-color-primary-01;
        border: 0.5px solid $sg-color-primary-05;
        cursor: pointer;

        a {
            text-decoration: none;
            color: $color__black;
        }
    }

    &-details {
        margin-top: 16px;
        padding: 24px;

        .actions-secondary {
            padding: 0 24px 24px;
        }
    }

    &-name {
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        max-height: 40px;
        min-height: 40px;
        overflow: hidden;
    }

    &-info {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-photo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 48px;
    }

    .price-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-direction: column-reverse;
        column-gap: 8px;

        .price {
            white-space: nowrap;
        }
    }

    .special-price,
    .minimal-price {
        .price {
            font-weight: bold;
        }

        .price-wrapper {
            display: inline-block;
        }

        .price-including-tax + .price-excluding-tax {
            display: block;
        }
    }

    .special-price,
    .old-price {
        .price-label {
            display: none;
        }
    }

    .old-price {
        color: $color__black;
        font-weight: normal;

        .tax_label {
            display: none;
        }
    }

    .minimal-price {
        .price-container {
            display: block;
        }
    }

    .normal-price {
        .price-container {
            display: flex;
            flex-wrap: wrap;

            .price-label {
                margin-right: 5px;
            }
        }
    }

    .minimal-price-link {
        margin-bottom: 5px;
    }

    .minimal-price-link,
    .price-excluding-tax,
    .price-including-tax {
        display: block;
        white-space: nowrap;
    }

    .price-including-tax {
        width: 100%;
        padding-top: 7px;
    }

    .price-from,
    .price-to {
        margin: 0;
    }

    .price-to {
        display: none;
    }

    .action.tocart {
        width: 100%;
        margin-top: 16px;
        padding: 8px;
    }

    .product-item-inner {
        .actions-secondary {
            display: none;
        }
    }
}
