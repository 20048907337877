// custom tabs styling
.column.main {
    .product.info.detailed {
        .items.fixed {
            .product.info.header {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                z-index: 100;
            }
        }
    }
}
