.cms-pages-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "banner banner banner banner"
        "second second third third"
        ". . . .";
    grid-gap: 16px;

    @include max-screen($screen__l) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "banner banner"
        "second third"
        ". .";
    }

    @include max-screen($screen__m) {
        grid-template-columns: 1fr;
        grid-template-areas:
        "banner"
        "second"
        "third"
        ".";
    }

    &__image {
        background-size: cover;
        background-position: center;
        aspect-ratio: 16/9;

        &--internal {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 24px;
    }

    &__title {
        color: $primary__color;
        font-size: 20px;
    }

    &__excerpt {
        margin: 0 0 8px 0;
        flex-grow: 1;
    }

    .pagebuilder-button-secondary {
        margin-bottom: 0;
    }

    &__item {
        color: $color__darkergrey;
        border-radius: 5px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        position: relative;
        transition: $transition-base;

        &:visited {
            color: $color__darkergrey;
        }

        &:hover,
        &:active {
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
            text-decoration: none;
            color: $color__darkergrey;
        }

        &:first-child {
            grid-area: banner;

            .cms-pages-list__image {
                aspect-ratio: unset;
                min-height: 320px;
                display: grid;
                align-items: center;

                @include max-screen($screen__m) {
                    background-image: none;

                    &--internal {
                        display: block;
                    }
                }
            }

            .cms-pages-list__container {
                background: $color_white;
                border-radius: 5px;
                width: 40%;
                margin: 16px 16px 16px 50%;

                @include max-screen($screen__l) {
                    width: 53%;
                    margin: 16px 16px 16px 33%;
                }

                @include max-screen($screen__m) {
                    width: 100%;
                    margin: 0;
                }
            }

            .cms-pages-list__title {
                font-size: 24px;
            }

            .cms-pages-list__excerpt {
                margin-bottom: 16px;
            }
        }

        &:nth-child(2) {
            grid-area: second;
        }

        &:nth-child(3) {
            grid-area: third;
        }
    }
}
