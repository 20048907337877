// custom merker page styling 
.merker-title {
    text-align: center;
    color: $primary__color;
    margin-top: 0!important;
    border-bottom: 1px solid $primary__color;
    padding-bottom: 10px;
}
.content.merker-page {
    .brands-filters {
        .letters {
            display: flex;
            justify-content: baseline;
            align-items: center;
            margin: 2rem 0;
            .letters-box {
                display: flex;
                width: 20%;
                .letter-all {
                    border-bottom: 2px solid #35875f;
                    background-color: $color-addtobutton;
                    padding: 5px 2rem;
                    color: #fff;
                    
                }
            }    
            .letters-box.alfabet {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                a {
                    font-size: 36px;
                    color: #8c8c8c;
                    display: flex;
                    text-decoration: underline;
                    &:hover {
                        color: $color__darkgrey;
                    }
                }
            }
        }    
    }
    .brands-content {
        .brands-letter {
            background-color: $color__lightgrey;
            display: flex;
            padding: 1rem;
            margin-bottom: 1rem;
            dt {
                display: flex;
                width: 20%;
                color: $primary__color;
                font-size: 36px;
                font-weight: 700;
                padding-left: 1rem;
            }
            dd {
                display: flex;
                width: 100%;
                list-style: none§;
                .bare-list {
                    list-style: none;
                    width: 100%;
                    .brand-item {
                        a {
                            background-color: #fff;
                            padding: 1rem;
                            width: calc(50% - 1rem);
                            margin: 0.5rem;
                            float: left;
                            color: #595959;
                            font-size: 25px;
                            font-weight: 300;
                            box-shadow: 0px 2px 3px rgba(0,0,0,0.15);
                            position: relative;
                            &:after {
                                content: "\E5C8";
                                font-size: 24px;
                                display: inline-block;
                                position: absolute;
                                top: 1rem;
                                right: 1rem;
                                font-family: 'Material icons';
                            }
                        }
                    }
                }
            }
        }
    }
}
// mobile styling 
@include max-screen($screen__s){
    .content.merker-page {
        .brands-filters {
            .letters {
                flex-direction: column;
                .letters-box {
                    width: 100%;
                }    
                .letters-box.alfabet {
                    margin-top: 1rem;
                    display: block;
                    a {
                        display: block;
                        padding: 0.5rem;
                        font-size: 25px;
                        float: left;
                    }
                }
            }    
        }
        .brands-content {
            .brands-letter {
                flex-direction: column;
                padding: 0.5rem;
                dt {
                }
                dd {
                    margin-bottom: 0;
                    .bare-list {
                        margin: 0;
                        padding: 0;
                        .brand-item {
                            margin: 0;
                            display: flex;
                            a {
                                width: 100%;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
