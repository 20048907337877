@font-face {
	font-family: "Infotext";
	font-weight: bold;
	src: url("../fonts/InfoTextPro-Bold.otf") format("opentype");
}
@font-face {
	font-family: "Infotext";
	font-weight: bold;
	font-style: italic;
	src: url("../fonts/InfoTextPro-BoldItalic.otf") format("opentype");
}
@font-face {
	font-family: "Infotext";
	font-weight: normal;
	src: url("../fonts/InfoTextPro-Book.otf") format("opentype");
}
@font-face {
	font-family: "Infotext";
	font-weight: normal;
	font-style: italic;
	src: url("../fonts/InfoTextPro-BookItalic.otf") format("opentype");
}
@font-face {
	font-family: "Infotext";
	font-weight: 100;
	src: url("../fonts/InfoTextPro.otf") format("opentype");
}
