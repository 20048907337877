.cms-home {
  .page-main {
    background: #ffffff;
  }
  .block-products-list {
    .slick-slider .slick-arrow.slick-prev {
      @include max-screen($screen__m + 1) {
        left: -10px;
      }

    }
    .slick-slider .slick-arrow.slick-next {
      @include max-screen($screen__m + 1) {
        right: -15px;
      }
    }
    .block-content {
      .products-grid {
        .product-items {
          overflow: hidden;
          &.slick-slider {
            overflow: visible;
          }
        }
      }
    }
  }
}