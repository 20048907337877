#html-body{
    .kurs-post-content-row {
        max-width: 920px;
        margin: 1rem auto 0 auto;
        .kurs-post-title {
            margin-bottom: 1rem;
            font-size: 2.9rem;
            font-weight: 700;
            line-height: 64px;
        }
        .kurs-post-text {
            margin-bottom: 1rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 22px;
        }
        .kurs-post-button-container {
            margin-bottom: 2.5rem;
            .kurs-primary-button {
                margin-right: 1rem;
                .pagebuilder-button-primary{
                    width: fit-content;
                    font-weight: 700;
                    font-size: 1rem;
                }
            }
            .kurs-secondary-button {
                .pagebuilder-button-secondary{
                    min-width: fit-content;
                    font-weight: 700;
                    font-size: 1rem;
                }
            }
        }
        .kurs-post-image {
            margin-bottom: 2.5rem;
        }
        .pagebuilder-column-group{
            .kurs-post-content-container-1 {
                width: 550px;
                margin-right: 2.5rem;
                .kurs-content-title {
                    font-weight: 700;
                    font-size: 1.5rem;
                    margin: 0;
                }
                .kurs-content-text {
                    margin-bottom: 2.5rem;
                    font-size: 1rem;
                }
                .kurs-content-subtitle {
                    font-weight: 700;
                    margin: 0;
                    font-size: 1.2rem;
                }
                .kurs-content-list {
                    display: list-item;
                    font-size: 1rem;
                    margin-left: 1.5rem;
                }

            }
            .kurs-post-content-container-2 {
                width: 230px;
                .kurs-content-right-subtitle {
                    font-weight: 700;
                    font-size: 1.1rem;
                    margin: 0;
                    display: flex;
                }
                .kurs-content-right-text {
                    margin-bottom: 1.2rem;
                    font-size: 1rem;
                    margin-left: 2.7rem;
                }
                .main-goal-icon {
                    &:before {
                        content: "\E80C";
                        font-family: "Material icons";
                        font-size: 1.6rem;
                        color: #ff5e03;
                        display: flex;
                        margin-right: 1rem;
                    }
                }
                .price-icon {
                    &:before {
                        content: "\E8A1";
                        font-family: "Material icons";
                        font-size: 1.6rem;
                        color: #ff5e03;
                        display: flex;
                        margin-right: 1rem;
                    }
                }
                .duration-icon {
                    &:before {
                        content: "\E192";
                        font-family: "Material icons";
                        font-size: 1.6rem;
                        color: #ff5e03;
                        display: flex;
                        margin-right: 1rem;
                    }
                }
            }
            .kurs-content-button {
                margin-bottom: 2.5rem;
                .pagebuilder-button-secondary {
                    font-weight: 700px;
                    min-width: fit-content;
                    padding: 12px;
                }
            }
        }
        .kurs-content-divider{
            margin-bottom: 2.5rem;
            hr {
                border-style: solid;
            }
        } 
    }
    .kurs-sign-up-row {
        max-width: 630px;
        margin: 0 auto;
    }
}



//
//  tablet
//  _____________________________________________
@include screen($screen__m,$screen__l){ 
    #html-body {
        .kurs-post-content-row {
            .pagebuilder-column-group{
                .kurs-post-content-container-2 {
                    width: fit-content;
                }
            }
        }
    }
}
//
//  mobile
//  _____________________________________________
@include max-screen($screen__m){ 
    #html-body{
        .kurs-post-content-row {
            .kurs-post-image {
                margin-bottom: 2.5rem;
            }
            .pagebuilder-column-group{
                .kurs-post-content-container-1 {
                    width: fit-content;
                    .kurs-content-title {
                        margin-top: 1.5rem;
                    }
                }
            }
        }
        .kurs-column-container {
            .pagebuilder-column-group{
                flex-direction: column-reverse;

            }
        }
    }
}