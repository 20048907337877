.brands {
  .wpcloud-slick-wrapper {
    .slick-arrow {
      position: static;
      margin: 0;
      padding: 0;
      transform: none;
    }
    .slick-prev {
    }
    .slick-next {
    }
    .slick-list {
      margin: 0 1rem;
    }
    .slick-slide {
      img {
        max-height: 4rem;
        margin: auto;

        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }
    }
    .slick-track {
      display: flex;
      align-items: center;
    }

    &:not(.slick-initialized) {
      display: none;
    }
  }
}
