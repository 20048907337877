.vestteknikk-news-grid.wpcmdl-grid {
  flex-direction: column;
  align-items: inherit;

  @include min-screen($screen__l) {
    flex-direction: row;
  }

  &-title {
    .posts-title {
      font-size: 32px;
    }

    a {
      i {
        transform: translateX(0);
        transition: 300ms transform ease-in-out;
      }

      &:hover i {
        transform: translateX(0.25rem);
      }
    }
  }

  .news-wrapper {
    display: flex;
    flex: 1;
    margin: 0.5rem 0;


    &:first-of-type {
      margin: 0 0 0.5rem 0;
    }

    &:last-of-type {
      margin: 0.5rem 0 0 0;
    }

    @include min-screen($screen__l) {
      margin: 0 0.5rem;


      &:first-of-type {
        margin: 0 0.5rem 0 0;
      }

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}

@mixin large-post {
  .wpcloud-post-thumbnail {
    @include min-screen($screen__m) {
      position: relative;
      height: auto;
    }
  }

  .image-wrapper {
    @include min-screen($screen__m) {
      padding-top: 55%;
    }
    @include min-screen($screen__l) {
      padding-top: 45%;
    }
  }

  .post-info {
    @include min-screen($screen__m) {
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 50%;
      width: 65%;
      max-width: auto;
      border-radius: 6px;
      transform: translate(50%, -50%);
      display: block;
    }
    @include min-screen($screen__l) {
      right: 0;
      transform: translate(-4.5rem, -50%);
      max-width: 600px;
      width: auto;
    }

    .post-title {
      @include min-screen($screen__m) {
        font-size: 36px;
        margin-top: 4px;
      }
    }
  }
}

@mixin medium-post {
  .image-wrapper {
    @include min-screen($screen__m) {
      padding-top: 42%;
    }
  }
  .wpcloud-post-thumbnail {
    @include min-screen($screen__m) {
      height: auto;
    }
  }
}

.news-page-grid {
  .wpcloud-post-thumbnail {
    overflow: hidden;
  }

  .wpcmdl-cell {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      width: calc(100% - #{$layout-indent__width});
    }

    &:nth-child(1) {
      @include large-post;
    }

    &:nth-child(2),
    &:nth-child(3){
      @include medium-post;
      @include min-screen($screen__m) {
        width: calc(50% - #{$layout-indent__width});
      }
    }
  }
}