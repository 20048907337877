// main colors
$primary__color: #ff5e03;
$secondary__color: #009dca;
$tertiary__color: #F9F7F5;
$color__text: #666666;
$color__text-grey: #595959;
$color__darkergrey: #222222;
$color__darkgrey: #5e5e5e;
$color__lightgrey: #f7f7f7;
$primary__blue: #00A8D2;
$color__green: #49ba83;
$color__darkblue: #007C99;
$color__darkgreen: #35875f;
$link__color: #2980b9;
$color__black: #262626; //'Core/Black' in Figma
$color__lightgrey2: #efefef;
$color__darkgrey2: #626262;
$color__darkgrey3: #595959;
$color__grey9: #171717;
$color_white: #fff;
$color_alto: #ddd;
$color_mine_shaft: #333;
$color_wild_sand: #F5F5F5;

$sg-color-brand: #FF7324;
$sg-color-brand-700: #CC4A00;
$sg-color-gray-09: #786B5E;
$sg-color-primary-01: #FFF8F5;
$sg-color-primary-02: #FFD1B8;
$sg-color-primary-03: #FF9D66;
$sg-color-primary-05: #FF5E03;
$sg-color-primary-06: #CC4A00;
$sg-color-primary-08: #802E00;
$sg-color-overlay: rgba(255, 94, 3, .25);

// page background colors
$page__background-color: #fff;

// header colors
$polarcore-nav-sections-mobile__background-color: $primary__color;
$navigation__background: $primary__color;
$navigation__item-color: #fff;
$navigation__item-color-hover: #fff;
$header__background-color: #fff;
$header-panel__background-color: $color__lightgrey;
$color-orange-red1: $primary__color;
$polarcore-primary-button__background: $primary__blue;
$polarcore-primary-button__border: $color__darkblue;


// footer colors
$footer__background-color: $sg-color-primary-01;
$copyright__background-color: $primary__color;

// catgory colors
$toolbar-element-background: #fff;
$border-color__base: transparent;
$toolbar-element-background: #fff;
$border-color__base: #000;

// product colors
$tab-control__background-color: transparent;
$text__color__muted: #333;
$color-addtobutton: $primary__blue;
$addto-border: $color__darkblue;
$color-addto-hover: darken(#00A8D2, 10%);
$polarcore-primary-cart-button__box-shadow: none;
$polarcore-primary-cart-button__border: $color__darkblue;
$polarcore-primary-cart-button__background: $primary__blue;

// mobile menu colors
$polarcore-navigation-link-mobile__color: #fff;
$polarcore-nav-sections-mobile__background-color: #fff;

// account colors
$collapsible-nav-color: $primary__color;
