$product-title__margin: 40px 0 10px;
$checkout-success__margin: 50px 0 0 0;
$product-title__font-size: 26px;

.checkout-success {
  margin: $checkout-success__margin;
  text-align: center;

  .page-title {
    color: $link__color;
    font-size: $font-size__base * 2;
    font-weight: 300;
    text-transform: uppercase;
  }

  .product-title {
    margin: 40px 0 20px;
    font-size: 26px;
  }

  p {
    font-weight: 300;
  }
}
