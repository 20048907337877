@include min-screen($screen__m) {
    .activities.scroll {
        overflow-y: scroll;
        max-height: 550px;
        padding: 5px;

        .activity__wrapper {
            margin: 0.5rem 0 1rem 0;
        }
    }
}

.activity {
    &__wrapper {
        display: grid;
        grid-gap: 1rem;
        align-items: flex-start;
        padding: 1rem;
        margin: 1rem 0;
        background-color: #ffffff;
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.15);
        border-radius: 5px;
        @include min-screen($screen__l) {
            grid-template-columns: 3rem 2.5fr 1fr;
        }
    }

    &__type {
        text-align: center;
        color: $primary__color;
        .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: $primary__color;
            color: #ffffff;
        }
    }

    &__description {
        h3 {
            margin: 0;
            font-size: $font-size__base;
            font-weight: bold;
        }
        a {
            color: $text__color;
        }
    }

    &__details {
        .date,
        .location {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
        }
        strong {
            margin-left: 0.5rem;
        }
    }

    .action.primary.cms {
        width: 100%;
    }
}