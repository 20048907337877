// custom product design
.catalog-product-view {
    background-color: #fff;
}
.column.main {
    .product-info-main {
        .page-title-wrapper.product {
            .page-title {
                font-size: 26px;
                font-weight: 700;
            }
        }
        .bullets {
            .stock-status {
                width: 100%;
                text-align: left;
                padding: 0.5rem 0 0;
                ul {
                    list-style: none;
                    padding-left: 0;
                    li {
                        font-size: $font-size__base;
                        color: $text__color;
                        font-weight: 300;
                        &:before {
                            content: " ";
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            background-color: $primary__color;
                            margin-right: 0.5rem;
                        }
                    }
                }
                .stock-qty li {
                    display: flex;
                    align-items: center;
                    span {
                        padding-left: 8px;
                    }
                }
            }
            .extra-links {
                display: flex;
                flex-direction: column;
                a {
                    display: flex;
                    align-items: center;
                    padding-bottom: 0.5rem;
                    &:hover {
                        text-decoration: none;
                    }
                    .material-icons {
                        color: $text__color;
                        font-size: 17px;
                        padding-right: 5px;
                    }
                    span {
                        text-decoration: underline;
                    }
                }
            }
            .stock-status {
                ul {
                    li {
                        .material-icons {
                            color: $color__green;
                        }
                        &:before {
                            content: "";
                            display: none;
                        }
                    }
                }
            }
        }

        #product-addtocart-button {
            position: relative;
        }

        #product-addtocart-button + .amquote-addto-button {
            width: auto;
            margin: 0;
        }

        .amquote-addto-button {
            margin-top: 10px;
            width: 100%;
            height: 48px;
            min-width: 95px;
        }

        .product-info-price {
            .product-reviews-summary.empty {
                display: none;
            }
            .price-box {
                .price,
                .price-container .price-wrapper .price {
                    font-size: 25px;
                    color: $text__color;
                    font-weight: 700;
                }

                .old-price {
                    text-decoration: none;

                    .price {
                        text-decoration: line-through;
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
            }
        }

        .price-box.price-final_price.ask-for-price {
            display: none;
        }

        .price-box.price-final_price {
            .normal-price .price-container {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;

                .price-label {
                    margin-right: 10px;
                }
            }
        }

        .datablad {
            margin: 1rem 0;
            background-color: transparent;
            a {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                padding: 0.5rem 0;
                font-size: 20px;
                color: #636363;
                &:hover {
                    text-decoration: none;
                    color: black;
                }
                .material-icons {
                    margin-top: -1px;
                    font-size: 31px;
                }

                &::after {
                    content: "";
                    height: 25px;
                    width: 25px;
                    margin-left: 5px;
                    background-image: url(../images/download-icon.svg);
                    background-size: contain;
                }
            }
        }
        .product-add-form {
            .super-attribute-select {
                line-height: 2;
                height: auto;
                padding: 0 0.5rem;
            }
            .box-tocart .fieldset .qty .control #qty {
                height: 48px;
                border-radius: 3px 0 0 3px;
            }
            .box-tocart .actions {
                #product-addtocart-button {
                    border-radius: 0 3px 3px 0;
                    padding: 0 1rem;
                    background: #ff5e03;
                    box-shadow: none;

                    &:hover {
                        background: darken(#ff5e03, 10%);
                    }
                }
                #te-pricing-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            .table-wrapper.grouped {
                strong.product-item-name {
                    font-size: 20px;
                }

                .grouped-total-price {
                    display: inline-block;
                }
            }
        }
        .product-info-stock-sku {
            display: flex;
            justify-content: space-between;
            .product.attribute.sku {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
            }
        }
    }
    .product.media {
        .amlabel-position-wrapper {
            z-index: 1;
        }
        .product-video iframe {
            z-index: 0;
        }
    }
    // tabs
    .product.info.detailed {
        .product.data.items {
            display: flex;
            .product.data.info.header {
                background-color: $color__lightgrey;
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 1rem 2rem;
                z-index: 997;
                .data.item.title {
                    padding: 0 2rem;
                    margin-top: 0;
                    border: none;
                    order: -1;
                    width: auto;
                    .data.switch {
                        background-color: transparent;
                        width: 100%;
                        font-size: 20px;
                        color: $text__color;
                        font-weight: 300;
                        padding: 1.5rem 1rem;
                        text-align: center;
                        &:after {
                            display: none;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    &.active {
                        .data.switch {
                            padding-bottom: 0.5rem;
                            border-bottom: 4px solid $primary__color;
                        }
                    }
                }
            }
            .product.info.content-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                .data.item.content {
                    display: flex;
                    margin: 6rem 1rem 0;
                    border: none;
                    h2 {
                        width: 20%;
                        text-align: right;
                        padding-right: 2rem;
                    }
                    .content-info {
                        width: 80%;
                        .faq {
                            white-space: pre-line;
                        }
                        .description {
                            .value {
                                white-space: pre-line;
                            }
                        }
                        .additional-attributes-wrapper {
                            margin-top: 1rem;
                            #product-attribute-specs-table {
                                tbody {
                                    tr {
                                        th {
                                            background-color: #f0f0f0;
                                            border-right: 1px solid #fff;
                                            padding: 1rem 2rem 1rem 0.5rem;
                                        }
                                        td {
                                            background-color: #f0f0f0;
                                            padding: 1rem 0.5rem;
                                            width: 100%;
                                        }
                                        &:nth-of-type(2n) {
                                            th {
                                                background-color: $color__lightgrey;
                                                border-right: 1px solid #fff;
                                                padding: 1rem 2rem 1rem 0.5rem;
                                            }
                                            td {
                                                background-color: $color__lightgrey;
                                                padding: 1rem 0.5rem;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .block.related {
                    width: 100%;
                }
                .am-attachments .am-fileline {
                    margin-bottom: 1rem;

                    .am-filelink {
                        display: inline-flex;
                        padding: 0.5rem 1rem;
                        background-color: $color__darkgrey;
                        color: #fff;
                        align-items: center;
                        line-height: 1.5;
                        &:hover {
                            background-color: darken($color__darkgrey, 10);
                            text-decoration: none;
                        }
                        &:after {
                            content: "vertical_align_bottom";
                            font-family: "Material icons";
                            font-size: 20px;
                            color: #fff;
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }
}
// mobile styling
@include max-screen($screen__m) {
    .column.main {
        .product.info.detailed {
            margin-bottom: 0;
            .product.data.items {
                flex-wrap: wrap;
                .product.data.info.header {
                    padding: 1rem 0 0.5rem;
                    margin: 0 -1rem;
                    width: 100vw;
                    justify-content: flex-start;
                    overflow-x: auto;
                    overflow-y: hidden;
                    .data.item.title {
                        display: flex;
                        padding: 0;
                        .data.switch {
                            padding: 0 1rem;
                            font-size: 16px;
                            border-bottom: 4px solid transparent;
                        }
                    }
                }
                &.fixed {
                    .product.data.info.header {
                        margin: 0;
                    }
                }
                .product.info.content-wrapper {
                    flex-wrap: wrap;

                    .data.item.content {
                        flex-wrap: wrap;
                        margin: 0;
                        width: 100%;

                        h2 {
                            width: 100%;
                            text-align: left;
                            padding: 0;
                            margin: 0 0 1rem;
                        }

                        .content-info {
                            width: 100%;

                            iframe {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
