// custom styling footer - styles/extensions/vestteknikk/_footer.scss
.page-footer {
    background: $footer__background-color;
    margin-top: auto;
    padding-bottom: $indent__m;

    .footer.content {
        color: $color__darkergrey;
        background: $sg-color-primary-01;
        border-top: 0;
        padding-top: 0.5rem;
        font-size: $font-size__base;
        display: flex;
        flex-wrap: wrap;

        > * {
            flex-grow: 1;
        }

        a {
            color: $color__darkergrey;
            margin: 0.5rem 0;
            font-size: 14px;
            display: inline-block;
        }

        .footer-container {
            display: flex;
            flex-direction: column;
            padding: 0;
            max-width: $layout__max-width;

            @include min-screen($screen__m) {
                margin: auto;
                flex-direction: row;
                padding: 1rem 0.5rem;
                flex-wrap: wrap;
            }

            h3 {
                font-size: 22px;
                line-height: 28px;
                font-weight: 700;
                color: $primary__color;
                margin: 16px 0;
            }

            strong {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }

            a {
                display: flex;
                align-items: center;

                &::before {
                    background-position: center;
                    background-repeat: no-repeat;
                    content: "";
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    margin-right: 0.6rem;
                }
            }

            .phone a:before {
                background-image: svg-uri(
                    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3332 16.5999V14.0999C18.3433 13.6912 18.2029 13.2931 17.9386 12.9812C17.6744 12.6693 17.3047 12.4654 16.8999 12.4083C16.0998 12.3027 15.3143 12.107 14.5582 11.8249C14.2599 11.7128 13.9358 11.6885 13.6241 11.755C13.3125 11.8215 13.0264 11.9759 12.7999 12.1999L11.7415 13.2583C9.65524 12.072 7.92783 10.3445 6.74153 8.25825L7.79986 7.19992C8.02392 6.97334 8.17832 6.68729 8.24478 6.37565C8.31125 6.06401 8.28698 5.73985 8.17486 5.44158C7.89274 4.68552 7.69705 3.89997 7.59153 3.09992C7.53493 2.69946 7.33464 2.33324 7.028 2.06953C6.72136 1.80582 6.32928 1.6626 5.92486 1.66658H3.42486C3.19348 1.6668 2.96468 1.7152 2.75302 1.80869C2.54137 1.90218 2.3515 2.03871 2.1955 2.2096C2.03951 2.38049 1.9208 2.58199 1.84695 2.80127C1.77309 3.02055 1.7457 3.25281 1.76653 3.48325C2.04007 6.05908 2.91651 8.53426 4.32486 10.7083C5.60431 12.7217 7.31139 14.4288 9.32486 15.7083C11.489 17.1117 13.9522 17.988 16.5165 18.2666C16.7477 18.2875 16.9806 18.2599 17.2005 18.1855C17.4203 18.1112 17.6222 17.9917 17.7933 17.8348C17.9643 17.6779 18.1006 17.487 18.1936 17.2744C18.2866 17.0617 18.3341 16.832 18.3332 16.5999Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                );
            }

            .email a:before {
                background-image: svg-uri(
                    '<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3333 3.66675H3.66665C2.65831 3.66675 1.83331 4.49175 1.83331 5.50008V16.5001C1.83331 17.5084 2.65831 18.3334 3.66665 18.3334H18.3333C19.3416 18.3334 20.1666 17.5084 20.1666 16.5001V5.50008C20.1666 4.49175 19.3416 3.66675 18.3333 3.66675Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.1666 5.5L11 11.9167L1.83331 5.5" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                );
            }

            :not(.social) section {
                margin-bottom: 16px;
            }

            .contact-us {
                a {
                    margin: 0;

                    &:before {
                        display: none;
                    }
                }
            }

            @include min-screen($screen__l) {
                .customer-service,
                .contact-us,
                .social,
                .block.newsletter {
                    width: 25%;
                }
            }

            @include screen($screen__m, $screen__l) {
                .customer-service,
                .contact-us,
                .social,
                .block.newsletter {
                    width: 50%;
                }
            }

            .social {
                display: flex;
                flex-direction: column;
                order: 3;

                a {
                    font-size: $font-size__base;

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }
                }

                .facebook:before {
                    background-image: svg-uri(
                        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5002 1.66675H15.0002V5.00008H12.5002C12.2791 5.00008 12.0672 5.08788 11.9109 5.24416C11.7546 5.40044 11.6668 5.6124 11.6668 5.83342V8.33342H15.0002L14.1668 11.6667H11.6668V18.3334H8.3335V11.6667H5.8335V8.33342H8.3335V5.83342C8.3335 4.72835 8.77248 3.66854 9.55388 2.88714C10.3353 2.10573 11.3951 1.66675 12.5002 1.66675Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    );
                }

                .linkedin:before {
                    background-image: svg-uri(
                        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.869 8.13121C15.9313 7.19353 14.6596 6.66675 13.3335 6.66675C12.0074 6.66675 10.7356 7.19353 9.79796 8.13121C8.86028 9.0689 8.3335 10.3407 8.3335 11.6667V17.5001H11.6668V11.6667C11.6668 11.2247 11.8424 10.8008 12.155 10.4882C12.4675 10.1757 12.8915 10.0001 13.3335 10.0001C13.7755 10.0001 14.1994 10.1757 14.512 10.4882C14.8246 10.8008 15.0002 11.2247 15.0002 11.6667V17.5001H18.3335V11.6667C18.3335 10.3407 17.8067 9.0689 16.869 8.13121Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.99984 7.5H1.6665V17.5H4.99984V7.5Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.33317 5.00008C4.25365 5.00008 4.99984 4.25389 4.99984 3.33341C4.99984 2.41294 4.25365 1.66675 3.33317 1.66675C2.4127 1.66675 1.6665 2.41294 1.6665 3.33341C1.6665 4.25389 2.4127 5.00008 3.33317 5.00008Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    );
                }

                .youtube:before {
                    background-image: svg-uri(
                        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.199 4.29942C18.4829 4.59206 18.6845 4.95443 18.7835 5.34992C19.0478 6.8155 19.1762 8.30238 19.1668 9.79158C19.1721 11.2585 19.0438 12.7229 18.7835 14.1666C18.6845 14.5621 18.4829 14.9244 18.199 15.2171C17.9152 15.5097 17.5591 15.7223 17.1668 15.8333C15.7335 16.2166 10.0002 16.2166 10.0002 16.2166C10.0002 16.2166 4.26682 16.2166 2.83348 15.8333C2.44917 15.7281 2.09847 15.5256 1.81527 15.2453C1.53207 14.965 1.32595 14.6165 1.21682 14.2333C0.952457 12.7677 0.824134 11.2808 0.833484 9.79158C0.826181 8.31351 0.954496 6.83788 1.21682 5.38325C1.31581 4.98776 1.51741 4.6254 1.80127 4.33276C2.08513 4.04012 2.44119 3.82757 2.83348 3.71659C4.26682 3.33325 10.0002 3.33325 10.0002 3.33325C10.0002 3.33325 15.7335 3.33325 17.1668 3.68325C17.5591 3.79424 17.9152 4.00679 18.199 4.29942Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.9167 9.79165L8.125 12.5167V7.06665L12.9167 9.79165Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    );
                }

                .instagram:before {
                    background-image: svg-uri(
                        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1423_2572)"><path d="M18.3332 5.83342C18.3332 3.53223 16.4677 1.66675 14.1665 1.66675H9.99984H5.83317C3.53198 1.66675 1.6665 3.53223 1.6665 5.83342V14.1667C1.6665 16.4679 3.53198 18.3334 5.83317 18.3334H14.1665C16.4677 18.3334 18.3332 16.4679 18.3332 14.1667V5.83342Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.9947 11.4992C13.3176 10.8769 13.4361 10.1685 13.3333 9.47501C13.2283 8.76757 12.8987 8.11263 12.393 7.60693C11.8873 7.10123 11.2324 6.77158 10.5249 6.66667C9.83138 6.56383 9.12307 6.68229 8.50074 7.00521C7.87841 7.32812 7.37374 7.83905 7.05852 8.46532C6.74331 9.09159 6.63359 9.8013 6.74497 10.4935C6.85636 11.1857 7.18318 11.8252 7.67895 12.321C8.17472 12.8167 8.81419 13.1436 9.50641 13.255C10.1986 13.3663 10.9083 13.2566 11.5346 12.9414C12.1609 12.6262 12.6718 12.1215 12.9947 11.4992Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.5835 5.41675H14.5918" stroke="#786B5E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1423_2572"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>'
                    );
                }

                @include min-screen($screen__l) {
                    padding: 0 1rem;
                }
            }

            .block.newsletter {
                order: 1;
                flex: 1;
                margin: 0;

                p {
                    padding: 0.5rem 0;
                }

                a:before {
                    display: none;
                }

                .content {
                    p {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin: 7px 0;

                        span {
                            margin-right: 8px;
                        }

                        a {
                            color: $sg-color-primary-06;
                            text-decoration: underline;
                        }
                    }

                    .control {
                        position: relative;

                        input {
                            border: 1px solid $sg-color-gray-09;
                            padding-left: 38px;
                            height: 44px;

                            @include lib-input-placeholder() {
                                color: $color__black;
                                opacity: 1;
                            }
                        }

                        .email-icon {
                            background-image: svg-uri(
                                '<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3333 3.66675H3.66665C2.65831 3.66675 1.83331 4.49175 1.83331 5.50008V16.5001C1.83331 17.5084 2.65831 18.3334 3.66665 18.3334H18.3333C19.3416 18.3334 20.1666 17.5084 20.1666 16.5001V5.50008C20.1666 4.49175 19.3416 3.66675 18.3333 3.66675Z" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.1666 5.5L11 11.9167L1.83331 5.5" stroke="#786B5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                            );
                            background-position: center;
                            content: "";
                            margin-right: 0.6rem;
                            background-repeat: no-repeat;
                            position: absolute;
                            top: 13px;
                            left: 11px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                @include min-screen($screen__m) {
                    order: 4;
                }
                @include min-screen($screen__l) {
                    flex: unset;
                    margin-right: 0;
                }
                @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
                    width: 60%;
                    margin-left: 0;
                }
            }
        }
    }
}

.copyright {
    color: #ffffff;
    font-size: 15px;
    font-weight: 300;
    background-color: $color__darkgrey3;
    display: block;
    padding: $indent__s;
    text-align: center;
}

// Mobile
@include max-screen($screen__m) {
    .page-footer {
        .footer.content {
            .footer-container {
                flex-direction: row;
                flex-wrap: wrap;

                .block.newsletter {
                    order: 4;
                    width: 100%;
                    flex: auto;
                }

                .customer-service {
                    order: 1;
                    width: 100%;
                }

                .contact-us {
                    order: 2;
                    width: 100%;
                }

                .social {
                    order: 3;
                    width: 100%;
                }

                h3 {
                    font-size: 22px;
                }
            }
        }
    }
}
