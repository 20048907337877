#html-body {
    // Default styles for PB Slick Slider.
    // Refer to this file when making changes to Slick styles:
    // vendor/magento/module-page-builder/view/frontend/web/css/source/slick/_slick.less
    .slick-prev,
    .slick-next {
        width: 44px;
        height: 44px;
        margin: 0;
        padding: 0;
        top: 45%;
        background-color: $sg-color-primary-01;
        border: 1px solid $sg-color-primary-02;
        border-radius: 2px;
        transition: $transition-base;

        &:before,
        &:after {
            color: $sg-color-primary-05;
            font-size: 14px;
            padding: 0;
        }

        &:hover {
            background-color: $sg-color-primary-02;
        }
    }

    .slick-prev {
        left: -16px;
    }

    .slick-next {
        right: -16px;
    }

    .slick-dots {
        li {
            button {
                border: 1px solid $sg-color-primary-05;
            }

            &.slick-active {
                button {
                    background: $sg-color-primary-05;
                }
            }
        }
    }

    /*
        This class is a work around for magento bug with tinyMCE customization.
        Once magento fixes it, the class should be replaced with ul[data-list-type='arrow'] selector
    */
    .all-lists-as-arrows {
        ul,
        ol {
            list-style-type: none;
            list-style-image: url("../images/list-arrow.svg");
            padding: 0 0 0 24px;

            li {
                font-size: 16px;
                padding-left: 12px;
                transition: $transition-base;

                &::marker {
                    font-size: 1.4em;
                }

                &:hover,
                &:active {
                    margin-left: 8px;
                }
            }

            a {
                color: $color__black;
                text-decoration: none;

                &:hover,
                &:active {
                    color: $sg-color-primary-06;
                }
            }
        }
    }

    [data-content-type="row"] {
        h2 {
            font-size: 28px;
            line-height: 36px;
            font-weight: 700;
        }

        [data-element="inner"] {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    margin: 8px !important;

                    [data-content-type="buttons"] {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }

    [data-content-type="products"] {
        &[data-appearance="carousel"] {
            &.center-mode .product-item {
                opacity: 1;
            }

            .slick-initialized li.product-item {
                margin: 0 4px;
            }
        }
    }

    [data-content-type="cs_collapsible"] {
        margin-bottom: 8px;

        .collapsible-title {
            cursor: pointer;
            padding: 4px 0;
            position: relative;
            display: flex;
            justify-content: space-between;
        }

        [data-element="title"] {
            font-size: 18px;
        }

        .collapsible-content {
            padding: 8px 0;
        }

        .icon {
            fill: $sg-color-gray-09;
        }

        .collapsible-collapse__icon {
            display: none;
        }

        &.active {
            .collapsible-collapse__icon {
                display: block;
            }

            .collapsible-expand__icon {
                display: none;
            }
        }
    }

    .main-banner {
        .pagebuilder-column-group {
            .pagebuilder-column {
                [data-content-type="text"] {
                    height: auto;
                    margin-bottom: 0.5rem;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .main-banner-heading {
                    p {
                        font-size: 42px;
                        line-height: 48px;
                        margin: 0 0 0.5rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .card {
        [data-content-type="heading"] {
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
        }

        .pagebuilder-column-group {
            .pagebuilder-column {
                padding: 1rem;
                border: 1px solid $sg-color-primary-03;
                &.no-border {
                    border: none;
                    padding: 0;
                }
                [data-content-type="heading"] {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }

    div[data-content-type="text"] {
        height: 100%;
    }

    div[data-content-type="video"] iframe {
        width: 100%;
        height: 300px;
    }

    .buttons-container {
        margin-top: 20px;

        .pagebuilder-button-secondary {
            border-radius: 2px;
            width: 235px;
            margin-right: 20px;
        }
    }

    .pagebuilder-button-primary {
        background-color: $sg-color-primary-05;
        color: $color_white;
        text-align: center;
        border: none;
        border-radius: 2px;
        padding: 12px 24px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        transition: $transition-base;
        width: auto;
        font-family: $font-family__base;

        &:hover {
            background-color: darken($sg-color-brand, 15%);
            text-decoration: none;
        }
    }

    .pagebuilder-button-secondary {
        border: 1px solid $sg-color-primary-05;
        padding: 12px 24px;
        border-radius: 2px;
        display: block;
        width: auto;
        min-width: unset;
        border: 1px solid $sg-color-primary-03;
        color: $color__darkergrey;
        background-color: $color_white;
        transition: $transition-base;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        font-family: $font-family__base;

        &:hover {
            color: $sg-color-primary-08;
            background-color: $sg-color-primary-02;
            text-decoration: none;
        }
    }

    .secondary-btn-dark-text {
        color: $color__darkergrey;
        margin-top: 1rem;
    }

    .pagebuilder-button-link {
        color: $color__darkergrey;
        text-align: center;
        border: none;
        border-radius: 2px;
        padding: 12px 16px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        transition: $transition-base;
        width: auto;
        text-decoration: none;

        &:hover {
            color: $sg-color-primary-08;
            background-color: $sg-color-primary-02;
            text-decoration: none;
        }

        &:after {
            background-position: center;
            background-repeat: no-repeat;
            content: "";
            width: 20px;
            height: 20px;
            margin-left: 7px;
            margin-bottom: -5px;
            display: inline-block;
            background-image: svg-uri(
                '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.1665 10H15.8332" stroke="#FF5E03" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="#FF5E03" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            );
        }
    }

    .category-title {
        margin: 0 0.5rem;
    }

    .activities.scroll {
        display: flex;
        padding: 0;
        overflow-y: hidden;

        .activity {
            .activity__wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-width: 330px;
                min-height: 370px;
                margin-right: 20px;
                padding: 0;
                border: 0.5px solid $sg-color-primary-05;
                border-radius: 0;
                box-shadow: none;

                .activity__type {
                    width: 100px;
                    background-color: $sg-color-primary-05;

                    .circle {
                        display: none;
                    }

                    p {
                        color: $color_white;
                    }
                }

                .activity__description {
                    padding: 0 1rem;
                }

                .activity__details {
                    padding-left: 1rem;
                    padding-bottom: 1rem;

                    .specifics {
                        display: flex;
                        flex-direction: row-reverse;
                        margin-bottom: 0.5rem;

                        .location {
                            margin-right: 10px;

                            svg {
                                path {
                                    fill: $sg-color-primary-05;
                                }
                            }
                        }

                        .date {
                            svg {
                                path {
                                    fill: $sg-color-primary-05;
                                }
                            }
                        }
                    }

                    .action.primary.cms {
                        border-radius: 0;
                        border: 0.5px solid $sg-color-primary-05;
                        background-color: $color_white;
                        color: $sg-color-primary-05;
                        box-shadow: none;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

[data-content-type="banner"] {
    &[data-appearance="collage-left"],
    &[data-appearance="collage-right"],
    &[data-appearance="collage-centered"] {
        [data-element="wrapper"] {
            padding: 32px;
        }
    }

    .pagebuilder-banner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .pagebuilder-overlay {
            width: 100%;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    #html-body {
        .fullwidth-mobile {
            margin: 20px 0 !important;

            .pagebuilder-column-group {
                flex-direction: column-reverse;

                .pagebuilder-column {
                    width: 100%;
                }
            }
        }

        .card {
            padding: 1rem 1rem 2rem;
        }

        .row-full-width-inner {
            align-self: auto;
        }

        .main-banner {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    img {
                        margin-bottom: 1.5rem;
                        min-width: 100%;
                    }

                    [data-content-type="text"] {
                        padding: 0 1rem;
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    [data-content-type="buttons"] {
                        padding: 0 1rem;
                    }

                    .main-banner-heading {
                        padding: 0 1rem;
                        p {
                            font-size: 34px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }

        .news-container,
        .service-container,
        .popular-container,
        .theme-page-container {
            padding: 20px 0 40px !important;

            .pagebuilder-column-group {
                display: flex;
                overflow-y: scroll;
                width: 100%;

                .pagebuilder-column {
                    min-width: 280px;
                }
            }
        }

        .service-container,
        .news-container,
        .theme-page-container {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    h4 {
                        font-size: 24px;
                        margin-bottom: 9px;
                    }
                }
            }
        }

        .kurs-container,
        .best-seller-container {
            padding: 20px 0 40px !important;
        }

        .partners-container {
            padding: 20px 0 40px !important;

            .pagebuilder-column-group {
                display: flex;
                overflow-y: scroll;
                width: 100%;

                .pagebuilder-column {
                    align-items: center;
                    min-width: calc(50% - 2rem);
                    margin: 0 1rem;
                }
            }
        }

        .activities.scroll {
            .activity {
                .activity__wrapper {
                    min-width: 280px;
                    min-height: 393px;
                }
            }
        }

        .mobile-half-width {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    margin: 8px;
                    width: calc(50% - 16px);
                }
            }
        }
    }

    [data-content-type="banner"] {
        &[data-appearance="collage-left"],
        &[data-appearance="collage-right"],
        &[data-appearance="collage-centered"] {
            [data-element="wrapper"] {
                padding: 0;
            }
        }

        .pagebuilder-banner-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }
}

//
//  Tablet
//  _____________________________________________
@include screen($screen__m, $screen__l) {
    #html-body {
        .fullwidth-mobile {
            margin: 20px 0 !important;

            .pagebuilder-column-group {
                flex-direction: column-reverse;

                .pagebuilder-column {
                    width: 100%;
                }
            }
        }
        .main-banner {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    img {
                        margin-bottom: 1.5rem;
                        min-width: 100%;
                    }

                    [data-content-type="text"] {
                        padding: 0 1rem;
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    [data-content-type="buttons"] {
                        padding: 0 1rem;
                    }

                    .main-banner-heading {
                        padding: 0 1rem;
                        p {
                            font-size: 34px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }

        .news-container,
        .service-container,
        .popular-container,
        .theme-page-container {
            padding: 20px 0 40px !important;

            .pagebuilder-column-group {
                display: flex;
                overflow-y: scroll;
                width: 100%;

                .pagebuilder-column {
                    min-width: 320px;
                    justify-content: space-between;
                }
            }
        }

        .service-container,
        .news-container,
        .theme-page-container {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    h4 {
                        font-size: 24px;
                        margin-bottom: 9px;
                    }
                }
            }
        }

        .kurs-container,
        .best-seller-container {
            padding: 20px 0 40px !important;
        }

        .partners-container {
            padding: 20px 0 40px !important;

            .pagebuilder-column-group {
                display: flex;
                overflow-y: scroll;
                width: 100%;

                .pagebuilder-column {
                    align-items: center;
                    min-width: calc(33.33% - 2rem);
                    margin: 0 1rem;
                }
            }
        }

        .activities.scroll {
            .activity {
                .activity__wrapper {
                    min-width: 320px;
                    min-height: 393px;
                }
            }
        }
    }
}
