.breadcrumbs {
    @include lib-breadcrumbs($_icon-font-size: $breadcrumbs-icon-font-size);
    padding: 0.5rem;
    margin: 0 auto;
}

@include min-screen($screen__m) {
    .breadcrumbs {
        padding: 0.5rem 1rem;
    }
}
