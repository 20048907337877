.block-category-link,
.block-product-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget {
    padding: 0;

    .pager {
        padding: 0;

        .toolbar-amount {
            float: none;
            font-size: 12px;
        }

        .pages-item-previous {
            padding-left: 0;
        }

        .pages-item-next {
            position: relative;
        }

        .items {
            white-space: nowrap;
        }
    }
}

//
//    Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}
