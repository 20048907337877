/* apply a natural box layout model to all elements, but allowing components to change */

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width: 1px;
}

.product.data.items > .item.title > .switch {
    box-sizing: content-box;
}

html, body {
    button,
    select,
    .cart.table-wrapper .actions-toolbar > .action,
    .cart.table-wrapper .action-gift,
    [type="reset"],
    [type="submit"],
    [type="button"] {
          appearance: none !important;
    }
}

/*
*
* Reset the static block widget so the breadcrumbs does not look off
*
*/
.top-container {
    .block-static-block.widget {
        margin-bottom: 0;
        margin-left: -$layout-indent__width;
        margin-right: -$layout-indent__width;
    }
}
