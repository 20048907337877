// custom cart styling
.cart-container {
    .form.form-cart {
        width: 70%;
    }

    .cart-summary {
        background-color: $color__lightgrey;
        padding: 20px;
        width: 30%;

        .summary.title {
            padding: 0;
            margin: 0 0 20px;
        }

        .block.discount {
            .title {
                strong {
                    margin: 0;
                }
            }
        }

        .action.primary.checkout,
        .action.apply.primary {
            background: $primary__color;
            box-shadow: none;

            &:hover {
                opacity: .8;
            }
        }

        .grand.totals {
            border-bottom: 1px solid $border-color__base;
        }

        .amquote-addto-button.amasty-quote-link {
            display: none;
        }
    }

    .form-cart {
        .cart #shopping-cart-table {
            .item {
                .item-info {
                    .product-item-details .product-item-name a {
                        font-size: $font-size__l;
                        font-weight: bold;
                    }
                }

                .item-actions {
                    td {
                        padding: 0;

                        .actions-toolbar {
                            padding-bottom: 1rem;

                            .action {
                                margin-bottom: 0;
                                background-color: $color__darkgrey;
                                color: #fff;

                                &:hover {
                                    background-color: darken(#5e5e5e, 10%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .cart.main.actions {
            .action.update {
                background-color: $color__darkgrey;
                color: #fff;

                &:hover {
                    background-color: darken(#5e5e5e, 10%);
                }
            }
        }
    }

    .form-cart .product-item-details .product-item-name {
        max-height: none;
    }

    .cart-summary
        .discount
        .content
        .actions-toolbar
        .primary
        .action.apply.primary {
        border-radius: 3px;
    }

    .cart-summary .checkout-methods-items .item .action.primary.checkout {
        border-radius: 3px;
    }

    #block-shipping {
        margin-bottom: 0.5rem;

        .title strong {
            padding: 0;
        }
    }
}

//
// tablet styling
//---------------------------------

@include screen($screen__m, $screen__l) {
    .cart-container {
        display: flex;
        flex-direction: column;

        .form-cart {
            width: 100%!important;
        }

        .cart-summary {
            width: 100%;
        }
    }
}

//
// mobile styling
//---------------------------------

@include max-screen($screen__m) {
    .cart-container {
        display: flex;
        flex-direction: column;

        .form-cart {
            width: 100%!important;

            .cart #shopping-cart-table {
                .item {
                    margin-bottom: 1rem;
                }
            }

            .item-info {
                .product-item-details {
                    padding-top: 0;
                    margin: 0;
                }
            }
        }

        .cart-summary {
            width: 100%;
        }
    }
}
