
.key-selling-points {
    width: 100%;
    text-align: left;
    border-top: 1px solid $sg-color-primary-03;
    border-bottom: 1px solid $sg-color-primary-03;
    margin: 16px 0;
    padding: 16px 0;
    min-height: 102px;
    overflow: hidden;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        li {
            width: 100%;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            height: 20px;
            margin: 0;

            &:before {
                content: " ";
                display: inline-block;
                width: 8px;
                height: 8px;
                background-color: $primary__color;
                margin-right: 8px;
            }

            &:nth-child(n+4) {
                display: none;
            }
        }
    }
}
