.checkout-container {
  @include min-screen($screen__m) {
    margin-top: 20px;
  }
}

.authentication-wrapper {
  margin-top: 0;
  margin-bottom: 1rem;
  float: none;

  @include min-screen($screen__m) {
    float: right;
    margin-top: 1rem;
    max-width: 50%;
    position: relative;
    z-index: 1;
  }
}