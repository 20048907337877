// custom general styling - styles/extensions/vestteknikk/_general.scss

.cms-page-view .page-wrapper #maincontent {
    padding-top: 0;
}

// customer account design
.sidebar-main .block-collapsible-nav .item.current a {
    border-color: $primary__color;
}
// full width wpcloud
.trollweb-wpcloud-page-view, .cms-home {
    #maincontent {
        max-width: none;
    }
}
// Magic scrooll test
.MagicToolboxContainer .MagicScroll .mcs-wrapper .mcs-items-container .mcs-item {
    height: auto!important;
    margin: 3rem 0;
}
@include max-screen($screen__m){
    .MagicToolboxContainer .MagicScroll .mcs-wrapper .mcs-items-container .mcs-item {
        height: auto!important;
        margin: 0;
    }    
}
