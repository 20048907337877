.page-header {
    .header.content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 102px;
        position: relative;

        @include max-screen($screen__m) {
            display: grid;
            grid-template-areas:
                    "logo logo links links"
                    "menutoggle search search search";
            grid-template-columns: 75px auto auto auto;
            column-gap: 8px;
            justify-content: unset; //if set, this somehow breaks the grid
        }

        .nav-toggle {
            display: none;
            cursor: pointer;

            @include max-screen($screen__m) {
                display: block;
                grid-area: menutoggle;
                margin-bottom: 10px;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                width: 74px;
                height: 42px;
                border: 1px solid $primary__color;
                border-radius: 2px;

                @include max-screen($screen__m) {
                    height: 40px;
                    border: 1px solid #575757;
                }
            }
        }

        .logo {
            height: 40px;
            flex-shrink: 0;

            @include screen($screen__m, $screen__l) {
                height: 32px;
            }

            @include max-screen($screen__m) {
                height: 28px;
                grid-area: logo;
            }

            img {
                height: 100%;
                display: block;
            }
        }

        .block-search {
            margin: 0 $indent__m;

            @include max-screen($screen__m) {
                grid-area: search;
                margin: 0;
                max-width: unset;
            }
        }

        .polarcore-header-links {
            display: flex;
            justify-content: flex-end;

            @include max-screen($screen__m) {
                grid-area: links;
                margin: 12px 0;
            }

            > div {
                width: 64px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .amquote-cart-wrapper {
                width: 80px;

                .amquote-showcart {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: inherit;
                    position: relative;

                    &:hover,
                    &:active {
                        text-decoration: none;
                    }
                }

                .text {
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    color: $color__black;
                }

                .clipboard-icon {
                    line-height: 10px;

                    &:before {
                        display: block;
                        width: 24px;
                        height: 24px;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIyNHB4IiBmaWxsPSIjMDAwMDAwIj48cGF0aCBkPSJNMjAwLTEyMHEtMzMgMC01Ni41LTIzLjVUMTIwLTIwMHYtNTYwcTAtMzMgMjMuNS01Ni41VDIwMC04NDBoMTY4cTEzLTM2IDQzLjUtNTh0NjguNS0yMnEzOCAwIDY4LjUgMjJ0NDMuNSA1OGgxNjhxMzMgMCA1Ni41IDIzLjVUODQwLTc2MHY1NjBxMCAzMy0yMy41IDU2LjVUNzYwLTEyMEgyMDBabTAtODBoNTYwdi01NjBIMjAwdjU2MFptODAtODBoMjgwdi04MEgyODB2ODBabTAtMTYwaDQwMHYtODBIMjgwdjgwWm0wLTE2MGg0MDB2LTgwSDI4MHY4MFptMjAwLTE5MHExMyAwIDIxLjUtOC41VDUxMC04MjBxMC0xMy04LjUtMjEuNVQ0ODAtODUwcS0xMyAwLTIxLjUgOC41VDQ1MC04MjBxMCAxMyA4LjUgMjEuNVQ0ODAtNzkwWk0yMDAtMjAwdi01NjAgNTYwWiIvPjwvc3ZnPgo=);
                        background-position: center;
                        content: '';
                        color: $color__black;
                        margin-bottom: 4px;
                    }
                }

                .counter.qty {
                    background-color: $primary__blue;
                    font-size: 10px;
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color_white;
                    border-radius: 5px;
                    position: absolute;
                    top: -10px;
                    right: 18px;

                    &.empty {
                        display: none;
                    }
                }
            }

            .account-link {
                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: inherit;

                    .account-icon {
                        line-height: 10px;

                        &:before {
                            display: block;
                            width: 24px;
                            height: 24px;
                            background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.5 21V19C20.5 17.9391 20.0786 16.9217 19.3284 16.1716C18.5783 15.4214 17.5609 15 16.5 15H8.5C7.43913 15 6.42172 15.4214 5.67157 16.1716C4.92143 16.9217 4.5 17.9391 4.5 19V21' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 11C14.7091 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7091 3 12.5 3C10.2909 3 8.5 4.79086 8.5 7C8.5 9.20914 10.2909 11 12.5 11Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                            background-position: center;
                            content: '';
                            color: $color__black;
                            margin-bottom: 4px;
                        }
                    }

                    &:hover,
                    &:active {
                        text-decoration: none;
                    }
                }

                .account-text {
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    color: $color__black;
                }
            }

            .widget {
                margin: 0;
            }

            .link {
                @include max-screen($screen__xs) {
                    &.contact-link,
                    &.about-link {
                        display: none;
                    }
                }

                a {
                    text-transform: uppercase;
                    color: $color__black;
                    font-size: 10px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    &:hover {
                        text-decoration: none;
                    }

                    .material-icons {
                        clear: both;
                        display: block;
                        margin: 0 auto;
                        color: $color__black;
                        text-align: center;
                        font-size: 24px;
                        margin-bottom: 4px;

                        &:visited {
                            color: $color__black;
                        }
                    }
                }
            }

            .minicart-wrapper {
                .showcart {
                    display: flex;
                    align-items: center;

                    &:before {
                        background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_655_381)'%3E%3Cpath d='M9.5 22C10.0523 22 10.5 21.5523 10.5 21C10.5 20.4477 10.0523 20 9.5 20C8.94772 20 8.5 20.4477 8.5 21C8.5 21.5523 8.94772 22 9.5 22Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.5 22C21.0523 22 21.5 21.5523 21.5 21C21.5 20.4477 21.0523 20 20.5 20C19.9477 20 19.5 20.4477 19.5 21C19.5 21.5523 19.9477 22 20.5 22Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.5 1H5.5L8.18 14.39C8.27144 14.8504 8.52191 15.264 8.88755 15.5583C9.25318 15.8526 9.7107 16.009 10.18 16H19.9C20.3693 16.009 20.8268 15.8526 21.1925 15.5583C21.5581 15.264 21.8086 14.8504 21.9 14.39L23.5 6H6.5' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_655_381'%3E%3Crect width='24' height='24' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                        background-position: center;
                        content: '';
                        width: 24px;
                        height: 24px;
                        color: $color__black;
                        margin-bottom: 4px;
                    }

                    .text {
                        display: block;
                        font-size: 10px;
                        text-transform: uppercase;
                        font-weight: 400;
                        letter-spacing: 0.5px;
                        color: $color__black;
                    }

                    .counter.qty {
                        margin-top: 0;
                        top: -13px;
                        right: -11px;
                        background-color: $primary__blue;

                        .counter-number {
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
    }
}

.checkout-index-index {
    .page-header .header.content .action.nav-toggle {
        display: none;
    }

    .page-header .header.content .logo {
        display: block;
        padding: 0;
    }
}
