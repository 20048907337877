.block.newsletter {
    margin: auto;
    margin-bottom: $indent__xl;

    .form.subscribe {
        display: table;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            display: block;

            &:before {
                position: absolute;
            }
        }
    }

    input {
        border-radius: 5px;
        border: 2px solid $color__darkergrey;

    }

    .title strong {
        display: block;
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
        width: 100%;
        color: $primary__color;
        padding: 0 2rem;
        margin-left: 1rem;
        position: relative;
        &:before {
            @include material-icon($icon-envelope);
            display: block;
            text-align: center;
            color: #ffffff;
            line-height: 42px;
            height: 42px;
            width: 42px;
            background: $primary__color;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }

    .action.subscribe.primary {
        border-radius: 5px;
        box-shadow: none;
        margin-left: 5px;
        height: 44px;
        width: 91px;
        font-size: $font-size__base;
        font-weight: 700;
        line-height: 1;
        text-transform: none;
        background: $primary__color;

        &:hover {
            background: darken($primary__color, 4%);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        width: 32%;

        .field {

            .control {
                width: 100%;
            }

        }

        .action.subscribe {
            border-radius: 3px;
        }
    }
}
