// account styling
.account {
    .sidebar.sidebar-main {
        #block-collapsible-nav {
            .nav.items {
                .nav.item.current {
                    strong {
                        border-color: $primary__color;
                    }
                }
            }
        }
    }

    .account-quote-buttons {
        button {
            background-color: $primary__color !important;
            border-color: $primary__color !important;
        }
    }
}

.amasty_company-role-edit,
.amasty_company-role-create{

    .fieldset > .field:not(.choice) > .label {
        text-align: left;
        float: none;
    }

    .amcompany-toolbar {
        .amcompany-button.-clear {
            width: auto;
        }
    }

}


.amasty_quote-quote-success  {
    .page-title-wrapper {
        text-align: center;
    }
}


.amcompany-customer-account {
    .order-details-items {
        border: none;
    }

    .data.table.table-order-items {
        td {
            text-align: left!important;
        }
    }

    .am-quotes-head {
        th {
            vertical-align: middle;
            text-align: left!important;
        }
    }
    .account-quote-buttons {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        justify-content: end;

        button {
            width: auto!important;
        }
    }

    .page-title-wrapper {
        .page-title,
        .order-status {
            vertical-align: middle;
        }

        .order-date {
            margin-top: 0;
        }
    }
}
