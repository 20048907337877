.amasty_quote-cart-index {

    .amasty-quote-update {
        .key-selling-points {
            display: none;
        }
    }

    .form.form-cart {
        width: 60%;

        .cart.table-wrapper {
            .cart.item {
                .field.price {
                    width: 100px;
                }
            }
        }
    }

    .cart-summary.amquote-cart-summary {
        background-color: $color__lightgrey;
        padding: 20px;
        width: 40%;

        button.checkout {
            background-color: $primary__color;
            box-shadow: none;
        }

        .title {
            margin-bottom: 20px;
            display: block;
        }

        .quote-details {
            .label {
                width: 35%;
            }

            .control {
                width: 65%;
            }

            .notes-loading {
                display: none;
                vertical-align: middle;
                margin-left: 10px;

                &._block-content-loading {
                    display: inline-block;
                }
            }

            .form.form-login {

                .actions-toolbar {
                    margin-bottom: 30px;
                }
            }
        }

        .field-tooltip-content {
            left: auto;
            right: 35px;

            &:after,
            &:before {
                left: auto;
                transform: rotate(180deg);
                right: -21px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .amasty_quote-cart-index {
        .form.form-cart,
        .cart-summary.amquote-cart-summary {
            width: 100%;
        }
    }
}

@include max-screen($screen__s) {
    .amasty_quote-cart-index {
        .cart-summary.amquote-cart-summary {
            .quote-details {
                .control {
                    width: 100%;
                }
            }
        }
    }
}

