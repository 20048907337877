.tw-productlabels {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;

    &__label {
        background: transparent;
        text-align: center;
    }

    &__text {
        color: #fff;
        border: 1px solid transparent;
        border-radius: 2px;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        padding: 8px;
    }

    // Custom label designs
    .primary-low {
        background: #FFF8F5;
        border: 1px solid #FF9D66;
        color: #CC4A00;
    }

    .primary-medium {
        background: #FFD1B8;
        color: #B34000;
    }

    .primary-high {
        background: #FF5E03;
    }

    .neutral-low {
        background: #F9F9FA;
        border: 1px solid #CFD3DA;
        color: #262626;
    }

    .neutral-medium {
        background: #DEE1E5;
        color: #262626;
    }

    .neutral-high {
        background: #240D00;
        color: #FFFFFF;
    }
}


// Mobile
@include max-screen($screen__m) {
    .tw-productlabels__text {
        font-size: 0.7rem;
    }
}
